import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  FaCircle, 
  FaTachometerAlt, 
  FaCalendarAlt, 
  FaClipboardList, 
  FaPaw, 
  FaMoneyBillWave, 
  FaEnvelope, 
  FaUserMd, 
  FaLock, 
  FaUnlock, 
  FaSignOutAlt,
  FaBoxes
} from 'react-icons/fa';

function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLocked, setIsLocked] = useState(false);

  const toggleSidebar = () => {
    setIsLocked(!isLocked);
    setIsCollapsed(isLocked);
  };

  const handleMouseEnter = () => {
    if (!isLocked) {
      setIsCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    if (!isLocked) {
      setIsCollapsed(true);
    }
  };

  return (
    <nav
      className={`bg-gray-800 text-white h-screen p-4 flex flex-col transition-all duration-300 ${
        isCollapsed ? 'w-20' : 'w-44'
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`flex justify-${isCollapsed ? 'center' : 'end'} transition-all duration-300`}>
        {/* Toggle sidebar with lock/unlock icon */}
        <button onClick={toggleSidebar} className="mb-6 text-white">
          {isLocked ? (
            <FaLock className="text-2xl" />
          ) : (
            <FaUnlock className="text-2xl" />
          )}
        </button>
      </div>

      {/* Menu List */}
      <ul className="flex flex-col space-y-4">
        {/* Dashboard */}
        <li className="flex items-center">
          <Link to="/overview" className="flex items-center space-x-4 hover:text-gray-300">
            <FaTachometerAlt className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Dashboard</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" /> {/* Separator */}
        <li className="flex items-center">
          <Link to="/overviewKanban" className="flex items-center space-x-4 hover:text-gray-300">
            <FaTachometerAlt className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">KanbanBoard</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" /> {/* Separator */}
        {/* Calendar */}
        <li className="flex items-center">
          <Link to="/calendar" className="flex items-center space-x-4 hover:text-gray-300">
            <FaCalendarAlt className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Calendar</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />

        {/* Appointments */}
        <li className="flex items-center">
          <Link to="/appointments" className="flex items-center space-x-4 hover:text-gray-300">
            <FaClipboardList className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Appointments</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />

        {/* Pet Owners */}
        <li className="flex items-center">
          <Link to="/patients" className="flex items-center space-x-4 hover:text-gray-300">
            <FaPaw className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Pet Owners</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />
        <li className="flex items-center">
          <Link to="/petlist" className="flex items-center space-x-4 hover:text-gray-300">
            <FaPaw className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Pet Lists</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />
        {/* Payments */}
        <li className="flex items-center">
          <Link to="/payments" className="flex items-center space-x-4 hover:text-gray-300">
            <FaMoneyBillWave className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Payments</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" /> 
        {/* Stock */}
       
        <li className="flex items-center">
          <Link to="/stock-management" className="flex items-center space-x-4 hover:text-gray-300">
            <FaBoxes className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Stok Yönetimi</span>}
          </Link>
        </li>
        {/* Messaging */}
        <li className="flex items-center">
          <Link to="/messaging" className="flex items-center space-x-4 hover:text-gray-300">
            <FaEnvelope className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Messaging</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />

        {/* Veterinarians */}
        <li className="flex items-center">
          <Link to="/veterinarians" className="flex items-center space-x-4 hover:text-gray-300">
            <FaUserMd className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Veterinarians</span>}
          </Link>
        </li>
        <hr className="border-gray-600 my-2" />

        {/* Logout */}
        <li className="flex items-center">
          <Link to="/" className="flex items-center space-x-4 hover:text-gray-300">
            <FaSignOutAlt className={`transition-all duration-300 ${isCollapsed ? 'text-3xl' : 'text-2xl'}`} />
            {!isCollapsed && <span className="text-lg">Logout</span>}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
