import React, { useState } from 'react';
import {
  FaPlus,
  FaHistory,
  FaChartLine,
  FaFileAlt,
  FaClinicMedical,
  FaNotesMedical,
  FaSyringe,
  FaPrescriptionBottleAlt,
  FaHospitalAlt,
  FaPhone,
  FaWhatsapp,
  FaArrowLeft,
} from 'react-icons/fa';
import Modal from 'react-modal';
import Chart from 'react-apexcharts';

Modal.setAppElement('#root');

function PetDetails({ onBack }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const pet = {
    name: "Bella",
    ownerName: "John Doe",
    ownerPhone: "1234567890",
    breed: "Golden Retriever",
    age: 5,
    weight: "30 kg",
    height: "60 cm",
    complaint: "Yok",
    diagnosis: "Belirtilmemiş",
    doctorNotes: "Enerjik ve dost canlısı.",
  };

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const categories = [
    {
      title: "Kategori 1",
      buttons: [
        { label: "Yeni Lab İstek", icon: <FaPrescriptionBottleAlt /> },
        { label: "Lab Geçmişi", icon: <FaHistory /> },
        { label: "Yeni Xray İstek", icon: <FaPrescriptionBottleAlt /> },
        { label: "Xray Geçmişi", icon: <FaHistory /> },
        { label: "Yeni Pacs İstek", icon: <FaPrescriptionBottleAlt /> },
        { label: "Pacs Geçmişi", icon: <FaHistory /> },
      ],
    },
    {
      title: "Kategori 2",
      buttons: [
        { label: "Yeni Randevu", icon: <FaPlus /> },
        { label: "Randevu Geçmişi", icon: <FaHistory /> },
        { label: "Yeni Ziyaret", icon: <FaClinicMedical /> },
        { label: "Ziyaret Geçmişi", icon: <FaHistory /> },
        { label: "Yeni Muayene", icon: <FaNotesMedical /> },
        { label: "Muayene Geçmişi", icon: <FaHistory /> },
        { label: "Hospitilizasyon", icon: <FaHospitalAlt /> },
      ],
    },
    {
      title: "Kategori 3",
      buttons: [
        { label: "Harcamalar", icon: <FaFileAlt /> },
        { label: "Fatura", icon: <FaFileAlt /> },
        { label: "Ekstreler", icon: <FaFileAlt /> },
        { label: "Geçmiş Ödemeler", icon: <FaHistory /> },
      ],
    },
    { title: "Doktor Notları", action: "Doktor Notları", icon: <FaNotesMedical /> },
    { title: "Hasta Fotoğraf Geçmişi", action: "Fotoğraf Geçmişi", icon: <FaFileAlt /> },
    { title: "Dosyalar", action: "Dosyalar", icon: <FaFileAlt /> },
    { title: "İstem Oluştur", action: "İstem Oluştur", icon: <FaPlus /> },
  ];

  const chartOptions = {
    chart: {
      height: '100%',
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6,
    },
    grid: {
      show: false,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: 0,
      },
    },
    series: [
      {
        name: 'New users',
        data: [6500, 6418, 6456, 6526, 6356, 6456],
      },
    ],
    xaxis: {
      categories: ['01 February', '02 February', '03 February', '04 February', '05 February', '06 February', '07 February'],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
  };

  return (
    <div className="p-6">
      {/* Back Button */}
      <button
        onClick={onBack}
        className="mb-4 flex items-center text-gray-700 hover:text-gray-900 transition"
      >
        <FaArrowLeft className="mr-2" />
        <span>Geri</span>
      </button>

      {/* Pet Information */}
      <div className="bg-gray-100 p-6 rounded-lg shadow mb-6">
        <h2 className="text-2xl font-bold mb-4">Evcil Hayvan Detayları</h2>
        <table className="w-full text-gray-700">
          <thead>
            <tr className="text-left font-semibold">
              <th className="py-2">Adı</th>
              <th className="py-2">Sahibi</th>
              <th className="py-2">Cins</th>
              <th className="py-2">Yaş</th>
              <th className="py-2">Kilo</th>
              <th className="py-2">Boy</th>
              <th className="py-2">Şikayet</th>
              <th className="py-2">Teşhis</th>
              <th className="py-2">Doktor Yorumu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2">{pet.name}</td>
              <td className="py-2">{pet.ownerName}</td>
              <td className="py-2">{pet.breed}</td>
              <td className="py-2">{pet.age}</td>
              <td className="py-2">{pet.weight}</td>
              <td className="py-2">{pet.height}</td>
              <td className="py-2">{pet.complaint}</td>
              <td className="py-2">{pet.diagnosis}</td>
              <td className="py-2">
                   {pet.doctorNotes}
                   <FaNotesMedical className="ml-1 inline text-blue-500 cursor-pointer" onClick={() => openModal("Doktor Notları")} />
                 </td>
               </tr>
             </tbody>
           </table>
           <div className="flex items-center space-x-4 mt-4">
             <div className="flex items-center space-x-2">
               <FaPhone className="text-gray-700" />
               <span>{pet.ownerPhone}</span>
             </div>
             <a
               href={`https://wa.me/${pet.ownerPhone}`}
               target="_blank"
               rel="noopener noreferrer"
               className="text-green-500 mt-1 inline-flex items-center"
               onClick={(e) => e.stopPropagation()} // Prevent row click
             >
               <FaWhatsapp className="mr-1" /> WhatsApp
             </a>
           </div>
         </div>

         {/* Two-Column Layout Below Pet Information */}
         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
           {/* Left Column: Graph and Recent Details */}
           <div className="space-y-6">
           <div className="max-w-full w-80 bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
  <div className="flex justify-between">
    <div>
      <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">30 kg</h5>
      <p className="text-base font-normal text-gray-500 dark:text-gray-400">Kilo Grafiği</p>
    </div>
    <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
      0.3 kg
      <svg className="w-3 h-3 ml-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4"/>
      </svg>
    </div>
  </div>
  <Chart options={chartOptions} series={chartOptions.series} type="area" height="150" width="100%" />
  <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
    <div className="flex justify-between items-center pt-5">
      <button
        className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
        type="button">
        Last 7 days
        <svg className="w-2.5 m-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
        </svg>
      </button>
      <a
        href="#"
        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
        Users Report
        <svg className="w-2.5 h-2.5 ml-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
      </a>
    </div>
  </div>
</div>


             <div className="bg-white p-4 rounded-lg shadow">
               <h3 className="text-lg font-semibold mb-2">Son Muayene ve Yemek Bilgisi</h3>
               <table className="w-full text-gray-700">
                 <thead>
                   <tr>
                     <th className="py-2">Son Muayene</th>
                     <th className="py-2">Son Yemek</th>
                     <th className="py-2">Durum</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td className="text-center py-2">2024-11-01</td>
                     <td className="text-center py-2">Köpek Maması</td>
                     <td className="text-center py-2">İyi</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>

           {/* Right Column: Category Buttons */}
           <div className="space-y-4">
             {categories.map((category, index) => (
               <div
                 key={index}
                 className="bg-gray-200 p-4 rounded-lg flex items-center justify-center cursor-pointer hover:bg-gray-300 transition"
                 onClick={() => openModal(category.title)}
               >
                 <span className="text-center font-semibold">{category.title}</span>
               </div>
             ))}
           </div>
         </div>

         {/* Modal for Category Buttons */}
         <Modal
           isOpen={modalIsOpen}
           onRequestClose={closeModal}
           className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg relative"
           overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
         >
           <button onClick={closeModal} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-xl">
             &times;
           </button>
           <h2 className="text-2xl font-bold mb-4">{modalContent}</h2>
           {categories
             .filter((cat) => cat.title === modalContent && cat.buttons)
             .map((cat, idx) => (
               <div key={idx} className="grid grid-cols-2 gap-4">
                 {cat.buttons.map((button, btnIdx) => (
                   <div
                     key={btnIdx}
                     className="bg-gray-100 p-4 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:bg-gray-200 transition"
                   >
                     <div className="text-2xl mb-2">{button.icon}</div>
                     <span>{button.label}</span>
                   </div>
                 ))}
               </div>
             ))}
         </Modal>
       </div>
     );
   }

   export default PetDetails;
