import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaEye, FaPlus, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';

function PetOwnerScreen() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedOwner, setExpandedOwner] = useState(null);
  const [owners, setOwners] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newOwner, setNewOwner] = useState({
    name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
  });
  const [petModalIsOpen, setPetModalIsOpen] = useState(false);
  const [newPet, setNewPet] = useState({ name: '', breed: '', age: '', owner_name: '', species: '' });

  useEffect(() => {
    axios.get('https://16.171.23.220:443/api/owners')
      .then(response => {
        setOwners(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the owners!', error);
      });
  }, []);

  const toggleExpand = (id) => setExpandedOwner(expandedOwner === id ? null : id);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setNewOwner({
      name: '', phone_number: '', address: '', email: '', tc_no: '', registration_date: '', protocol_no: '', last_visit_date: ''
    });
  };
  
  const openPetModal = () => setPetModalIsOpen(true);
  const closePetModal = () => {
    setPetModalIsOpen(false);
    setNewPet({ name: '', breed: '', age: '', owner_name: '', species: '' });
  };

  const handleAddOwner = () => {
    axios.post('https://16.171.23.220:443/api/owners', newOwner)
      .then(response => {
        setOwners([...owners, { ...newOwner, _id: response.data.owner_id, pets: [], total_price: 0 }]);
        closeModal();
      })
      .catch(error => {
        console.error('There was an error creating the owner!', error);
      });
  };

  const handleAddPet = () => {
    axios.post('https://16.171.23.220:443/api/pets', newPet)
      .then(response => {
        const updatedOwners = owners.map(owner => {
          if (owner.name === newPet.owner_name) {
            return { ...owner, pets: [...owner.pets, { ...newPet, _id: response.data.pet_id }] };
          }
          return owner;
        });
        setOwners(updatedOwners);
        closePetModal();
      })
      .catch(error => {
        console.error('There was an error adding the pet!', error);
      });
  };

  const handleDeleteOwner = (ownerId) => {
    if (window.confirm('Bu evcil hayvan sahibini silmek istediğinizden emin misiniz?')) {
      axios.delete(`https://16.171.23.220:443/api/owners/${ownerId}`)
        .then(() => setOwners(owners.filter(owner => owner._id !== ownerId)))
        .catch(error => console.error('There was an error deleting the owner!', error));
    }
  };

  const filteredOwners = owners.filter((owner) =>
    owner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    owner.phone_number.includes(searchTerm) ||
    owner.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
    owner.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-4 p-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Evcil Hayvan Sahibi Yönetimi</h2>
        <input
          type="text"
          placeholder="Ara..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border rounded-md"
        />
      </div>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={openModal}
          className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          <FaPlus />
          <span>Yeni Evcil Hayvan Sahibi Ekle</span>
        </button>
        <button
          onClick={openPetModal}
          className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          <FaPlus />
          <span>Yeni Evcil Hayvan Ekle</span>
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left"></th>
              <th className="px-6 py-3 text-left">İsim, Email ve Telefon</th>
              <th className="px-6 py-3 text-left">Adres</th>
              <th className="px-6 py-3 text-left">TC No</th>
              <th className="px-6 py-3 text-left">Kayıt Tarihi</th>
              <th className="px-6 py-3 text-left">Protokol No</th>
              <th className="px-6 py-3 text-left">Son Ziyaret Tarihi</th>
              <th className="px-6 py-3 text-left">Evcil Hayvan Sayısı</th>
              <th className="px-6 py-3 text-left">Toplam Fiyat</th>
              <th className="px-6 py-3 text-left">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredOwners.map((owner) => (
              <React.Fragment key={owner._id}>
                <tr className="border-b hover:bg-gray-100">
                  <td className="px-6 py-3">
                    <button
                      onClick={() => toggleExpand(owner._id)}
                      className="text-blue-500 hover:underline"
                    >
                      <FaChevronDown />
                    </button>
                  </td>
                  <td className="px-6 py-3">
                    <div className="flex flex-col">
                      <Link to={`/pet-owner/${owner._id}`} className="text-blue-500 font-bold">{owner.name}</Link>
                      <p className="text-sm text-gray-600">{owner.email}</p>
                      <p className="text-sm text-gray-600">{owner.phone_number}</p>
                    </div>
                  </td>
                  <td className="px-6 py-3">{owner.address}</td>
                  <td className="px-6 py-3">{owner.tc_no}</td>
                  <td className="px-6 py-3">{owner.registration_date}</td>
                  <td className="px-6 py-3">{owner.protocol_no}</td>
                  <td className="px-6 py-3">{owner.last_visit_date}</td>
                  <td className="px-6 py-3">{owner.pets ? owner.pets.length : 0}</td>
                  <td className="px-6 py-3">{owner.total_price}</td>
                  <td className="px-6 py-3 flex space-x-2">
                    <Link to={`/pet-owner/${owner._id}`} className="text-green-500 hover:underline"><FaEye /></Link>
                    <button onClick={() => handleDeleteOwner(owner._id)} className="text-red-500 hover:underline"><FaTrash /></button>
                  </td>
                </tr>

                {expandedOwner === owner._id && owner.pets && owner.pets.length > 0 && (
                  <tr>
                    <td colSpan="10" className="px-6 py-3 bg-gray-50">
                      <table className="min-w-full bg-white shadow-md rounded-lg">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 text-left">Fotoğraf</th>
                            <th className="px-6 py-3 text-left">İsim</th>
                            <th className="px-6 py-3 text-left">Cins</th>
                            <th className="px-6 py-3 text-left">Tür</th>
                            <th className="px-6 py-3 text-left">Doğum Tarihi</th>
                            <th className="px-6 py-3 text-left">İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {owner.pets.map((pet) => (
                            <tr key={pet._id} className="border-b hover:bg-gray-100">
                              <td className="px-6 py-3">
                                <img src={pet.photo || 'default-pet.jpg'} alt={pet.name} className="h-9 w-9 rounded-xl" />
                              </td>
                              <td className="px-6 py-3">
                                <Link to={`/pet-details/${pet._id}`} className="text-blue-500 hover:underline">{pet.name}</Link>
                              </td>
                              <td className="px-6 py-3">{pet.breed}</td>
                              <td className="px-6 py-3">{pet.species}</td>
                              <td className="px-6 py-3">{new Date(pet.date_of_birth).toLocaleDateString()}</td>
                              <td className="px-6 py-3">
                                <Link to={`/pet-details/${pet._id}`} className="text-green-500 hover:underline"><FaEye /></Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PetOwnerScreen;
