import React, { useState } from 'react';
import { FaCalendarPlus, FaUserPlus, FaShoppingCart, FaFileAlt, FaRandom, FaSyncAlt } from 'react-icons/fa';
import PetListModal from './PetListModal';

function ActionButtonKanban() {
  const [isPetListModalOpen, setIsPetListModalOpen] = useState(false);
  const openPetListModal = () => {
    setIsPetListModalOpen(true);
  };

  const closePetListModal = () => {
    setIsPetListModalOpen(false);
  };

  return (
    <div className="grid grid-cols-8 gap-2">
      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaCalendarPlus className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Yapılacaklar</span>
      </button>

      <button onClick={openPetListModal} className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaUserPlus className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Yatan Hasta Listesi</span>
      </button>

      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaShoppingCart className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Finans</span>
      </button>

      <button className="border-2 border-blue-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaFileAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Stok</span>
      </button>

      <button className="border-2 border-green-300 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaRandom className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">İstemler</span>
      </button>

      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Chat</span>
      </button>
      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Örnek İşlem 2</span>
      </button>
      <button className="border-2 border-green-400 p-6 rounded-lg shadow-sm hover:shadow-md flex flex-col items-center justify-center transition-shadow duration-200">
        <FaSyncAlt className="text-gray-700 text-3xl mb-2" />
        <span className="text-gray-700 font-semibold">Örnek İşlem 2</span>
      </button>
      <PetListModal isOpen={isPetListModalOpen} onRequestClose={closePetListModal} />

    </div>
  );
}

export default ActionButtonKanban;
