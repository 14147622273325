import React, { useState } from 'react';

function StockManagementScreen() {
  const [items, setItems] = useState([
    {
      name: 'İlaç A',
      type: 'İlaç',
      quantity: 20,
      reorderPoint: 10,
      expiryDate: '20/09/2024',
    },
    {
      name: 'Aşı B',
      type: 'Aşı',
      quantity: 5,
      reorderPoint: 3,
      expiryDate: '22/09/2024',
    },
    {
      name: 'Tıbbi Ekipman C',
      type: 'Tıbbi Ekipman',
      quantity: 2,
      reorderPoint: 1,
      expiryDate: '01/02/2025',
    },
    {
      name: 'İlaç D',
      type: 'İlaç',
      quantity: 30,
      reorderPoint: 15,
      expiryDate: '15/12/2024',
    },
    {
      name: 'Aşı E',
      type: 'Aşı',
      quantity: 10,
      reorderPoint: 5,
      expiryDate: '01/11/2024',
    },
  ]);

  const [newItem, setNewItem] = useState({
    name: '',
    type: 'İlaç',
    quantity: '',
    reorderPoint: '',
    expiryDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prevItem) => ({ ...prevItem, [name]: value }));
  };

  const handleAddItem = () => {
    setItems([...items, newItem]);
    setNewItem({ name: '', type: 'İlaç', quantity: '', reorderPoint: '', expiryDate: '' });
  };

  const handleDeleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Stok Yönetimi</h2>

      <table className="table-auto w-full mb-6 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2">Ürün Adı</th>
            <th className="border border-gray-300 px-4 py-2">Tür</th>
            <th className="border border-gray-300 px-4 py-2">Miktar</th>
            <th className="border border-gray-300 px-4 py-2">Yeniden Sipariş Noktası</th>
            <th className="border border-gray-300 px-4 py-2">Son Kullanma Tarihi</th>
            <th className="border border-gray-300 px-4 py-2">İşlem</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index} className="text-center">
              <td className="border border-gray-300 px-4 py-2">{item.name}</td>
              <td className="border border-gray-300 px-4 py-2">{item.type}</td>
              <td className="border border-gray-300 px-4 py-2">{item.quantity}</td>
              <td className="border border-gray-300 px-4 py-2">{item.reorderPoint}</td>
              <td className="border border-gray-300 px-4 py-2">{item.expiryDate}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleDeleteItem(index)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Sil
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3 className="text-xl font-bold mb-4">Yeni Ürün Ekle</h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div>
          <label>Ürün Adı</label>
          <input
            type="text"
            name="name"
            value={newItem.name}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label>Tür</label>
          <select
            name="type"
            value={newItem.type}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          >
            <option value="İlaç">İlaç</option>
            <option value="Aşı">Aşı</option>
            <option value="Tıbbi Ekipman">Tıbbi Ekipman</option>
          </select>
        </div>
        <div>
          <label>Miktar</label>
          <input
            type="number"
            name="quantity"
            value={newItem.quantity}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label>Yeniden Sipariş Noktası</label>
          <input
            type="number"
            name="reorderPoint"
            value={newItem.reorderPoint}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label>Son Kullanma Tarihi</label>
          <input
            type="date"
            name="expiryDate"
            value={newItem.expiryDate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="md:col-span-3">
          <button
            onClick={handleAddItem}
            className="bg-blue-500 text-white px-4 py-2 rounded mt-4 w-full"
          >
            Ürün Ekle
          </button>
        </div>
      </div>
    </div>
  );
}

export default StockManagementScreen;
