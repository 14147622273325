import React, { useState, useEffect } from 'react';
import axios from 'axios';

const categories = ['all', 'lab', 'istemler', 'yapılacaklar', 'operasyon'];
const statuses = ['To Do', 'In Progress', 'Done'];
const assignees = ['all', 'Clinic Worker', 'Assistant', 'Intern', 'Doctor', 'Nurse'];

function KanbanBoard() {
  const [tasks, setTasks] = useState({ todo: [], inProgress: [], done: [] });
  const [filter, setFilter] = useState('all');
  const [assigneeFilter, setAssigneeFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTask, setSelectedTask] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [draggedTask, setDraggedTask] = useState(null);
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    assignedTo: '',
    createdBy: '',
    assignedBy: '',
    category: 'lab',
    status: 'To Do',
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://16.171.23.220:443/api/tasks');
        const fetchedTasks = { todo: [], inProgress: [], done: [] };

        response.data.forEach((task) => {
          if (task.status === 'To Do') fetchedTasks.todo.push(task);
          else if (task.status === 'In Progress') fetchedTasks.inProgress.push(task);
          else if (task.status === 'Done') fetchedTasks.done.push(task);
        });
        setTasks(fetchedTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  const handleCreateTask = async () => {
    try {
      const response = await axios.post('https://16.171.23.220:443/api/tasks', newTask);
      setTasks((prevTasks) => ({
        ...prevTasks,
        todo: [...prevTasks.todo, response.data],
      }));
      setShowCreateModal(false);
      setNewTask({
        title: '',
        description: '',
        assignedTo: '',
        createdBy: '',
        assignedBy: '',
        category: 'lab',
        status: 'To Do',
      });
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const handleStatusChange = async (task, newStatus) => {
    try {
      await axios.put(`https://16.171.23.220:443/api/tasks/${task._id}`, {
        ...task,
        status: newStatus,
      });

      const updatedTasks = { ...tasks };
      const currentCategory = task.status === 'To Do' ? 'todo' : task.status === 'In Progress' ? 'inProgress' : 'done';
      const targetCategory = newStatus === 'To Do' ? 'todo' : newStatus === 'In Progress' ? 'inProgress' : 'done';

      updatedTasks[currentCategory] = updatedTasks[currentCategory].filter((t) => t._id !== task._id);
      updatedTasks[targetCategory] = [...updatedTasks[targetCategory], { ...task, status: newStatus }];

      setTasks(updatedTasks);
      setSelectedTask({ ...task, status: newStatus, currentCategory: targetCategory });
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  const handleDragStart = (task, category) => {
    setDraggedTask({ ...task, currentCategory: category });
  };

  const handleDrop = (category) => {
    if (draggedTask) {
      const newStatus = category === 'todo' ? 'To Do' : category === 'inProgress' ? 'In Progress' : 'Done';
      handleStatusChange(draggedTask, newStatus);
      setDraggedTask(null);
    }
  };

  const handleTaskClick = (task, category) => {
    setSelectedTask({ ...task, currentCategory: category });
  };

  const handleDeleteTask = async () => {
    if (selectedTask) {
      try {
        await axios.delete(`https://16.171.23.220:443/api/tasks/${selectedTask._id}`);
        const updatedTasks = { ...tasks };
        updatedTasks[selectedTask.currentCategory] = updatedTasks[selectedTask.currentCategory].filter(
          (t) => t._id !== selectedTask._id
        );
        setTasks(updatedTasks);
        setSelectedTask(null);
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    }
  };

  const filteredTasks = (tasks, category) => {
    return tasks
      .filter((task) => filter === 'all' || task.category === filter)
      .filter((task) => assigneeFilter === 'all' || task.assignedTo === assigneeFilter)
      .filter((task) => task.title.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  const renderTasks = (tasks, category, label) => (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={() => handleDrop(category)}
      className="bg-white shadow-lg rounded-lg p-4 mb-4 space-y-4 relative"
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-800">{label}</h3>
        <button onClick={(e) => { e.stopPropagation(); setShowCreateModal(true); }} className="text-xl font-bold text-blue-500">+</button>
      </div>
      {filteredTasks(tasks[category], category).map((task) => (
        <div
          key={task._id}
          draggable
          onDragStart={() => handleDragStart(task, category)}
          onClick={() => handleTaskClick(task, category)}
          className={`p-4 bg-gray-100 rounded-lg shadow cursor-pointer relative flex flex-col border-l-4 
            ${task.category === 'lab' ? 'border-red-500' : 
               task.category === 'istemler' ? 'border-blue-500' :
               task.category === 'yapılacaklar' ? 'border-green-500' :
               'border-purple-500'}`}
        >
          <p className="font-bold text-gray-800">{task.title}</p>
          <p className="text-sm text-gray-500">Created by: {task.createdBy}</p>
          <p className="text-sm text-gray-500">Assigned by: {task.assignedBy}</p>
          <div className="mt-auto text-right">
            <p className="text-xs text-gray-500 font-semibold">Assigned to: {task.assignedTo}</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search tasks..."
          className="p-2 border rounded w-1/4"
        />
        <div className="flex space-x-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setFilter(category)}
              className={`px-3 py-1 rounded border-l-4 ${
                filter === category
                  ? category === 'lab'
                    ? 'bg-red-500 text-white border-red-500'
                    : category === 'istemler'
                    ? 'bg-blue-500 text-white border-blue-500'
                    : category === 'yapılacaklar'
                    ? 'bg-green-500 text-white border-green-500'
                    : 'bg-purple-500 text-white border-purple-500'
                  : category === 'lab'
                  ? 'bg-gray-200 text-gray-800 border-red-500'
                  : category === 'istemler'
                  ? 'bg-gray-200 text-gray-800 border-blue-500'
                  : category === 'yapılacaklar'
                  ? 'bg-gray-200 text-gray-800 border-green-500'
                  : 'bg-gray-200 text-gray-800 border-purple-500'
              }`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        <select
          value={assigneeFilter}
          onChange={(e) => setAssigneeFilter(e.target.value)}
          className="p-2 border rounded"
        >
          {assignees.map((assignee) => (
            <option key={assignee} value={assignee}>
              {assignee}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>{renderTasks(tasks, 'todo', 'To Do')}</div>
        <div>{renderTasks(tasks, 'inProgress', 'In Progress')}</div>
        <div>{renderTasks(tasks, 'done', 'Done')}</div>
      </div>

      {selectedTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative">
            <button className="absolute top-2 right-2 text-gray-500" onClick={() => setSelectedTask(null)}>✖</button>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-xl font-semibold">{selectedTask.title}</h3>
                <textarea
                  placeholder="Add a description..."
                  className="w-full mt-2 p-2 border rounded"
                  value={selectedTask.description || ''}
                  onChange={(e) => setSelectedTask({ ...selectedTask, description: e.target.value })}
                />
                <div className="mt-4">
                  <h4 className="text-lg font-semibold">Comments</h4>
                  <textarea
                    placeholder="Add a comment..."
                    className="w-full mt-2 p-2 border rounded"
                  />
                </div>
              </div>
              <div>
                <label className="text-sm font-semibold">Status:</label>
                <select
                  className="w-full p-2 border rounded mt-1"
                  value={selectedTask.status}
                  onChange={(e) => handleStatusChange(selectedTask, e.target.value)}
                >
                  {statuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                <div className="mt-4 p-4 border rounded shadow">
                  <h4 className="text-lg font-semibold">Details</h4>
                  <p className="text-sm">Assigned To: {selectedTask.assignedTo}</p>
                  <p className="text-sm">Assigned By: {selectedTask.assignedBy}</p>
                  <p className="text-sm">Created By: {selectedTask.createdBy}</p>
                  <p className="text-sm">Category: {selectedTask.category}</p>
                </div>
                <button className="mt-4 w-full bg-red-500 text-white p-2 rounded" onClick={handleDeleteTask}>
                  Delete Task
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowCreateModal(false)}>
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-xl font-bold mb-4">Create Task</h2>
            <button className="absolute top-2 right-2 text-gray-500" onClick={() => setShowCreateModal(false)}>✖</button>
            
            {/* Title */}
            <label className="block text-gray-700 font-semibold">Title</label>
            <input
              type="text"
              placeholder="Enter task title"
              value={newTask.title}
              onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Description */}
            <label className="block text-gray-700 font-semibold">Description</label>
            <textarea
              placeholder="Enter task description"
              value={newTask.description}
              onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Assigned To */}
            <label className="block text-gray-700 font-semibold">Assigned To</label>
            <select
              value={newTask.assignedTo}
              onChange={(e) => setNewTask({ ...newTask, assignedTo: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            >
              <option value="" disabled>Select assignee</option>
              {assignees.map((assignee) => (
                <option key={assignee} value={assignee}>{assignee}</option>
              ))}
            </select>

            {/* Created By */}
            <label className="block text-gray-700 font-semibold">Created By</label>
            <input
              type="text"
              placeholder="Enter creator name"
              value={newTask.createdBy}
              onChange={(e) => setNewTask({ ...newTask, createdBy: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Assigned By */}
            <label className="block text-gray-700 font-semibold">Assigned By</label>
            <input
              type="text"
              placeholder="Enter assigner's name"
              value={newTask.assignedBy}
              onChange={(e) => setNewTask({ ...newTask, assignedBy: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            />

            {/* Category */}
            <label className="block text-gray-700 font-semibold">Category</label>
            <select
              value={newTask.category}
              onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
              className="w-full mb-2 p-2 border rounded"
            >
              <option value="" disabled>Select category</option>
              {categories.slice(1).map((category) => (
                <option key={category} value={category}>{category.charAt(0).toUpperCase() + category.slice(1)}</option>
              ))}
            </select>

            {/* Submit and Cancel Buttons */}
            <button onClick={handleCreateTask} className="bg-blue-500 text-white p-2 rounded mt-4 w-full">
              Submit
            </button>
            <button onClick={() => setShowCreateModal(false)} className="bg-gray-500 text-white p-2 rounded mt-2 w-full">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default KanbanBoard;
