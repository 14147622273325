import React from 'react';
import HeaderSection from '../Overview/HeaderSection';
import StatisticsWidgets from '../Overview/StatisticsWidgets';
import ActionButtonKanban from './ActionButtonKanban';
import AppointmentsSchedule from '../Overview/AppointmentsSchedule';
import ExpiringProducts from '../Overview/ExpiringProducts';
import KanbanBoard from '../Overview/KanbanBoard'; 

function OverviewKanban() {
  return (
    <div className="p-4">
      <div className="flex justify-between items-start space-x-4">
        <div className="flex-1">
         
          <div className="mt-6">
            
            <div className="mt-6 flex space-x-4">
            
              <div className="">
                <ActionButtonKanban />
              </div>
            </div>
            <div className="mt-6">
              <KanbanBoard /> 
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default OverviewKanban;
