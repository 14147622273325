import React from 'react';

function PaymentsScreen() {
  const payments = [
    { id: 1, amount: '$50.00', date: '2024-08-20', method: 'Credit Card', status: 'Paid' },
    { id: 2, amount: '$30.00', date: '2024-08-21', method: 'Cash', status: 'Pending' },
  ];

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Payments</h2>
      <ul>
        {payments.map((payment) => (
          <li key={payment.id} className="mb-2">
            {payment.date} - {payment.amount} - {payment.method} - {payment.status}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PaymentsScreen;
