import React, { useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';

function UserMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="relative">
      <button onClick={toggleMenu} className="flex items-center space-x-2">
        <FaUserCircle className="text-3xl" />
        <span className="text-lg font-medium">X Veteriner Kliniği </span>
      </button>
      {menuOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-lg py-2 z-20">
          <a href="/profile" className="block px-4 py-2 hover:bg-gray-100">Profile</a>
          <a href="/settings" className="block px-4 py-2 hover:bg-gray-100">Settings</a>
          <a href="/logout" className="block px-4 py-2 hover:bg-gray-100">Logout</a>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
