// PetListModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import PetDetails from './PetDetails'; // Import the new PetDetails component
import styles from './PetListModal.module.css';

Modal.setAppElement('#root'); // Ensure accessibility

function PetListModal({ isOpen, onRequestClose }) {
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);

  useEffect(() => {
    if (isOpen) {
      axios.get('https://16.171.23.220:443/api/owners')
        .then(response => {
          const allPets = response.data.flatMap(owner => 
            owner.pets.map(pet => ({
              ...pet,
              ownerName: owner.name,
              ownerPhone: owner.phone_number
            }))
          );
          setPets(allPets);
        })
        .catch(error => console.error('Error fetching pets:', error));
    }
  }, [isOpen]);

  const handleRowClick = (pet) => {
    setSelectedPet(pet);
  };

  const handleBackToList = () => {
    setSelectedPet(null);
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onRequestClose} 
      className={styles.modalContent} 
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <button onClick={onRequestClose} className={styles.closeButton}>
          <FaTimes size={20} />
        </button>
        
        {selectedPet ? (
          <PetDetails pet={selectedPet} onBack={handleBackToList} /> // Render PetDetails when a pet is selected
        ) : (
          <div>
            <h2 className="text-2xl font-bold mb-4">Yatan Hasta Listesi</h2>
            <div className={styles.tableContainer}>
              <table className="min-w-full bg-white shadow-md rounded-lg border">
                <thead>
                  <tr>
                    <th className={styles.tableHeader}>Fotoğraf</th>
                    <th className={styles.tableHeader}>Hasta Adı</th>
                    <th className={styles.tableHeader}>Sahip Bilgileri</th>
                    <th className={styles.tableHeader}>Cins</th>
                    <th className={styles.tableHeader}>Şikayet</th>
                    <th className={styles.tableHeader}>Tanı</th>
                    <th className={styles.tableHeader}>Durum</th>
                  </tr>
                </thead>
                <tbody>
                  {pets.map((pet) => (
                    <tr 
                      key={pet._id} 
                      className={styles.tableRow} 
                      onClick={() => handleRowClick(pet)}
                    >
                      <td className={styles.tableRow}>
                        <img 
                          src={pet.photo || 'https://via.placeholder.com/80'} 
                          alt={pet.name} 
                          className="h-9 w-9 rounded-full" 
                        />
                      </td>
                      <td className={styles.tableRow}>{pet.name}</td>
                      <td className={styles.tableRow}>{pet.ownerName} - {pet.ownerPhone}</td>
                      <td className={styles.tableRow}>{pet.breed}</td>
                      <td className={styles.tableRow}>{pet.complaint || 'Yok'}</td>
                      <td className={styles.tableRow}>{pet.diagnosis || 'Yok'}</td>
                      <td className={styles.tableRow}>{pet.status || 'Belirtilmemiş'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default PetListModal;
