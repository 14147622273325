import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaWhatsapp, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

function PetList() {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  // Dummy data for pets
  const pets = [
    {
      _id: '1',
      name: 'Max',
      ownerName: 'Ali C',
      ownerPhone: '5551234567',
      photoUrl: 'https://via.placeholder.com/80',
      complaint: 'Aşırı kaşıntı',
      diagnosis: 'Alerjik reaksiyon',
      species: 'Köpek',
      registrationDate: '01/01/2023',
      lastVisit: '15/09/2023',
      appointmentDate: '20/11/2023',
    },
    {
      _id: '2',
      name: 'Bella',
      ownerName: 'Ayşe D',
      ownerPhone: '5559876543',
      photoUrl: 'https://via.placeholder.com/80',
      complaint: 'İştahsızlık',
      diagnosis: 'Sindirim sorunu',
      species: 'Kedi',
      registrationDate: '10/02/2023',
      lastVisit: '18/09/2023',
      appointmentDate: '22/11/2023',
    },
    {
      _id: '3',
      name: 'Luna',
      ownerName: 'Veli B',
      ownerPhone: '5557654321',
      photoUrl: 'https://via.placeholder.com/80',
      complaint: 'Kusma',
      diagnosis: 'Mide enfeksiyonu',
      species: 'Köpek',
      registrationDate: '05/03/2023',
      lastVisit: '20/09/2023',
      appointmentDate: '25/11/2023',
    }
  ];

  // Filter pets based on search query
  const filteredPets = pets.filter((pet) =>
    pet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    pet.ownerName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="space-y-4 p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Evcil Hayvan Listesi</h2>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Ara..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-2 border rounded-md"
          />
          <button className="flex items-center space-x-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
            <FaPlus />
            <span>Yeni Hayvan Ekle</span>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left">Fotoğraf</th>
              <th className="px-6 py-3 text-left">Hasta Adı &amp; Sahip Bilgileri</th>
              <th className="px-6 py-3 text-left">Şikayet / Tanı &amp; Cins</th>
              <th className="px-6 py-3 text-left">Tarih Bilgileri</th>
              <th className="px-6 py-3 text-left">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredPets.map((pet) => (
              <tr
                key={pet._id}
                className="border-b hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate(`/pet-details/${pet._id}`)}
              >
                {/* Pet Photo */}
                <td className="px-6 py-3">
                  <img
                    src={pet.photoUrl}
                    alt={pet.name}
                    className="h-16 w-16 rounded-full object-cover"
                  />
                </td>

                {/* Owner Info */}
                <td className="px-6 py-3">
                  <div className="flex flex-col">
                    <span className="font-bold text-gray-800">{pet.name}</span>
                    <span className="text-gray-500">{pet.ownerName}</span>
                    <span className="text-gray-500">{pet.ownerPhone}</span>
                    <a
                      href={`https://wa.me/${pet.ownerPhone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-500 mt-1 inline-flex items-center"
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    >
                      <FaWhatsapp className="mr-1" /> WhatsApp
                    </a>
                  </div>
                </td>

                {/* Complaint, Diagnosis, and Species */}
                <td className="px-6 py-3">
                  <div className="flex flex-col space-y-2">
                    <div>
                      <span className="font-semibold text-gray-800">Şikayet:</span>
                      <p className="text-gray-500">{pet.complaint}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Tanı:</span>
                      <p className="text-gray-500">{pet.diagnosis}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Cins:</span>
                      <p className="text-gray-500">{pet.species}</p>
                    </div>
                  </div>
                </td>

                {/* Date Information */}
                <td className="px-6 py-3">
                  <div className="flex flex-col space-y-2">
                    <div>
                      <span className="font-semibold text-gray-800">Kayıt Tarihi:</span>
                      <p className="text-gray-500">{pet.registrationDate}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Son Ziyaret:</span>
                      <p className="text-gray-500">{pet.lastVisit}</p>
                    </div>
                    <div>
                      <span className="font-semibold text-gray-800">Randevu Tarihi:</span>
                      <p className="text-gray-500">{pet.appointmentDate}</p>
                    </div>
                  </div>
                </td>

                {/* Actions */}
                <td className="px-6 py-3 text-center">
                  <div className="flex space-x-2">
                    <Link
                      to={`/pet-details/${pet._id}`}
                      className="text-blue-500 hover:underline"
                      onClick={(e) => e.stopPropagation()} // Prevent row click
                    >
                      <FaEdit /> Düzenle
                    </Link>
                    <button
                      className="text-red-500 hover:underline"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        // Add delete functionality here
                      }}
                    >
                      <FaTrash /> Sil
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PetList;
